import { ThumbnailCarousel } from "artisn-ui-react";
import { Image, LightboxImage } from "artisn-ui-react";
import { CDNImage } from "artisn/types";
import React, { useEffect, useMemo, useState } from "react";
import Magnifier from "react-magnifier";

import { imageConfigurations } from "./ProductDetailsImage.helpers";
import { imageLightboxConfigurations } from "./ProductDetailsImage.helpers";
import Styles from "./ProductDetailsImage.styles";
import { ProductDetailsImageProps as Props } from "./ProductDetailsImage.types";
import useWindowSize from "hooks/useWindowSize";

const ProductDetailsImage: React.FC<Props> = props => {
  const { images, modifiers } = props;
  const [imageIndex, setImageIndex] = useState(0);
  const { width } = useWindowSize();
  const [showLightbox, setShowLightbox] = useState(false);
  const initialImages = useMemo(() => images || [], [images]);
  const [selectedFlavorImages, setSelectedFlavorImages] =
    useState<CDNImage[]>(initialImages);

  const { renderer } = modifiers ?? {};

  const doubleClickHandler = (index: number) => {
    setImageIndex(index);
    setShowLightbox(true);
  };

  useEffect(() => {
    if (!renderer) return;

    const imagesSelected: CDNImage[] = [];

    if (initialImages.length > 0) {
      imagesSelected.push(...initialImages);
    }

    renderer.forEach(({ modifiers }) => {
      modifiers.forEach(({ images, amount }) => {
        if (amount > 0) {
          imagesSelected.push(images[0]);
        }
      });
    });

    setImageIndex(0);

    if (imagesSelected.length > 0) {
      setSelectedFlavorImages(imagesSelected);
    } else {
      setSelectedFlavorImages(initialImages);
    }
  }, [initialImages, renderer]);

  const renderMobileNode = () => {
    if (!selectedFlavorImages || !selectedFlavorImages.length) return null;

    const selectedImage = selectedFlavorImages[imageIndex];
    return (
      <>
        <Image
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--mobile"
        />
        <ThumbnailCarousel
          slideWidth={64}
          slideHeight={64}
          images={selectedFlavorImages}
          className="ProductDetailsImage__thumbnail-carousel ProductDetailsImage__thumbnail-carousel--mobile"
          width={200}
          onClickItem={setImageIndex}
          onHoverItem={setImageIndex}
        />
      </>
    );
  };

  const renderDesktopNode = () => {
    if (!selectedFlavorImages || !selectedFlavorImages.length) return null;

    const selectedImage = selectedFlavorImages[imageIndex];

    return (
      <>
        <ThumbnailCarousel
          slideWidth={64}
          slideHeight={64}
          images={selectedFlavorImages}
          height={280}
          className="ProductDetailsImage__thumbnail-carousel ProductDetailsImage__thumbnail-carousel--desktop"
          vertical
          onClickItem={setImageIndex}
          onHoverItem={setImageIndex}
          onDoubleClickItem={doubleClickHandler}
        />
        <Image
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--tablet"
        />
        {/* @ts-ignore */}
        <Magnifier
          className="ProductDetailsImage__zoom-image--desktop"
          src={selectedImage?.url}
          mgShape="square"
          {...imageConfigurations(width)}
        />

        {showLightbox ? (
          <LightboxImage
            className="ProductDetailsImage__light-box--desktop"
            images={selectedFlavorImages}
            value={imageIndex}
            onClose={() => setShowLightbox(false)}
            onChange={setImageIndex}
            imageConfig={{
              alt: "Lightbox image preview",
              config: imageLightboxConfigurations(width)
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <Styles className="ProductDetailsImage">
      {renderDesktopNode()}
      {renderMobileNode()}
    </Styles>
  );
};

ProductDetailsImage.defaultProps = {};

export default ProductDetailsImage;
