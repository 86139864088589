import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import CONSTANTS from "../../../../config/constants";
import Styles from "./UserTabsMenuItem.styles";
import { UserTabsMenuItemProps as Props } from "./UserTabsMenuItem.types";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";

import UserSVG from "../../../../../public/assets/images/TabsMenu/hamburguer.svg";
import UserMobileSVG from "../../../../../public/assets/images/TabsMenu/user.svg";

const { Item } = TabsMenuUI;
const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const UserTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const { width } = useWindowSize();
  const isMobile = width <= tablet;
  const t = useI18n();

  const onClickHandler = async () => {
    await push("/profile");
  };

  return (
    <Styles className={`UserTabsMenuItem ${className}`} active={active}>
      <Item
        icon={isMobile ? UserMobileSVG : UserSVG}
        title={hideTitle ? undefined : t.navigation.user}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

UserTabsMenuItem.defaultProps = {
  className: ""
};

export default UserTabsMenuItem;
