import styled from "styled-components";

import { WelcomeStyledProps as Props } from "./Welcome.types";
import variables from "styles/util/variables";

const { fonts } = variables;

const WelcomeStyled = styled.p<Props>`
  color: var(--palette-white);
  font-family: ${fonts.primary};
  font-size: 1.4rem;
  font-weight: 500;
  max-width: 16rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .Welcome {
  }
`;

export default WelcomeStyled;
