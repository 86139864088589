import { useShoppingCart } from "@simple/contexts";
import { Badge, TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Styles from "./CartButton.styles";
import { CartButtonProps as Props } from "./CartButton.types";
import ShoppingCartDrawer from "components/global/drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";

import HeartSVG from "../../../../../public/assets/images/black-heart.svg";
import CartSVG from "../../../../../public/assets/images/cart.svg";
import FilledHeartSVG from "../../../../../public/assets/images/heart-primary-filled.svg";

const { Item } = TabsMenuUI;
const { ARTISN, FEATURE_FLAGS } = CONSTANTS;
const { WITH_WISHLIST, WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_ANONYMOUS, WITH_PURCHASE } = FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME } = ARTISN;
const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const CartButton: React.FC<Props> = props => {
  const { className, product } = props;
  const [showNotification, setShowNotification] = useState(false);
  const [opened, setOpened] = useState(false);
  const { pathname, push } = useRouter();
  const { shoppingCart } = useShoppingCart();
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const [disable, setDisable] = useState(false);
  const { isAnonymous } = useAuth();
  const isProductDetails = pathname === "/products/[productId]/[productName]";
  const desktop = !(
    typeof window !== "undefined" && window.matchMedia("(hover: none)").matches
  );

  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const icon = inCart ? FilledHeartSVG : HeartSVG;

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  const addProductHandler = async () => {
    if (!product || disable) return;
    setDisable(true);
    await addToWishlist();
    if (!inCart) setShowNotification(true);
    setDisable(false);
  };

  const cartHandler = () => {
    if (!WITH_ANONYMOUS && isAnonymous) {
      push("/signin");
      return;
    }
    if (WITH_CART_DRAWER) {
      setOpened(prev => !prev);
      dismissAddToCartNotification();
    } else {
      push("/cart");
    }
  };

  const heartNode = (
    <Item
      icon={icon}
      className="CartButton--heart"
      onClick={addProductHandler}
    />
  );

  const cartNode = (
    <Item icon={CartSVG} className="CartButton--cart" onClick={cartHandler} />
  );

  // Show everywhere execpt in the product details page when heart appears
  const showBadge =
    // Has products in shopping cart
    !!products?.length &&
    // If wishlist is disabled
    (!WITH_WISHLIST ||
      // Any page but product details is fine
      !isProductDetails ||
      // If product details only on desktop mode
      (isProductDetails && desktop));

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`CartButton ${className}`}
      isProductDetails={isProductDetails}
      isSignedIn={!isAnonymous}
    >
      {showBadge ? (
        <Badge value={`${products?.length}`}>
          <>
            {heartNode}
            {cartNode}
          </>
        </Badge>
      ) : (
        <>
          {heartNode}
          {cartNode}
        </>
      )}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer opened={opened} onClose={cartHandler} />
      ) : null}
    </Styles>
  );
};

CartButton.defaultProps = {
  className: ""
};

export default CartButton;
