import { Image } from "artisn-ui-react";
import React from "react";

import ModifierPrices from "../ModifierPrices/ModifierPrices";
import { PRODUCT_IMAGE_CONFIG } from "../ModifiersForm/ModifiersForm.helpers";
import Styles from "./CounterModifier.styles";
import { CounterModifierProps as Props } from "./CounterModifier.types";
import Counter from "components/global/Counter/Counter";

const CounterModifier: React.FC<Props> = props => {
  const { modifier, maxDisabled = false, disabled = false } = props;
  const { amount, name, max, totals, images } = modifier;
  const { handleChange, data } = modifier;
  const { prices } = data;
  const totalDisabled = maxDisabled && !amount;
  // TODO: Remove it if we're not going to use it again
  const activeClassName = amount ? "CounterModifier__title--active" : "";

  return (
    <Styles className="CounterModifier">
      <div className="CounterModifier__info">
        {images?.[0] ? (
          <Image
            alt={name}
            className="CheckboxModifier__image"
            image={images[0]}
            config={PRODUCT_IMAGE_CONFIG}
            errorImage="https://res.cloudinary.com/dovstvjim/image/upload/v1627933617/Propeller/product_false_kxyniy.jpg"
            placeholder="blur"
          />
        ) : null}
        <div className="CounterModifier__wrapper">
          <p className={`CounterModifier__title ${activeClassName}`}>{name}</p>
          <ModifierPrices
            totals={totals}
            prices={prices}
            netPriceStyle="CounterModifier__netPrice"
            unitPriceStyle="CounterModifier__unitPrice"
            className="CounterModifier__prices"
          />
        </div>
      </div>
      <Counter
        className="CounterModifier__counter"
        initialValue={amount ?? 0}
        max={max}
        min={0}
        onChange={handleChange}
        disabled={disabled ?? totalDisabled}
        maxDisabled={maxDisabled}
      />
    </Styles>
  );
};

CounterModifier.defaultProps = {};

export default CounterModifier;
