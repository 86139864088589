import { Switch as SwitchUI } from "artisn-ui-react";
import styled from "styled-components";

import { SwitchStyledProps as Props } from "./Switch.types";

const SwitchStyled = styled(SwitchUI)<Props>`
  && .Switch {
    &__slider {
      width: 4rem;
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-gray-s0-l35);
      border-radius: 1.2rem;
      overflow: hidden;

      &::before {
        background-color: var(--palette-gray-s0-l35);
      }
    }

    &__input {
      &:checked + .Switch__slider {
        border: none;
      }

      &:checked + .Switch__slider::before {
        transform: translate(1.6rem, -50%);
      }
    }

    &__wrapper {
      width: 4rem;
    }
  }
`;

export default SwitchStyled;
