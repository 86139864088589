import { useProducts } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { CommentBox, ShareButton, Clickable } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { applyBenefit, getShoppingCartProducts } from "artisn/shopping-cart";
import { Benefit } from "artisn/types";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo } from "react";
import { useRef, useState } from "react";

import ProductDetailsImage from "../ProductDetailsImage/ProductDetailsImage";
import ProductErrorMessage from "../ProductErrorMessage/ProductErrorMessage";
import Styles from "./ProductMain.styles";
import { ProductMainProps as Props } from "./ProductMain.types";
import Button from "components/global/Button/Button";
import Counter from "components/global/Counter/Counter";
import ProductBaseInfo from "components/global/ProductBaseInfo/ProductBaseInfo";
import Switch from "components/global/Switch/Switch";
import ModifiersForm from "components/global/modifiers/ModifiersForm/ModifiersForm";
import AddToCartButton from "components/products/AddToCartButton/AddToCartButton";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import AddToWishlistButton from "components/products/AddToWishlistButton/AddToWishlistButton";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";
import useOnUnmount from "hooks/useOnUnmount";
import { useProductForm } from "hooks/useProductForm";
import useShippingCost from "hooks/useShippingCost";
import { getFullPath, scrollToElement } from "utils/common.utils";
import { getBenefitProductId } from "utils/common.utils";
import { createAddToCartNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

import HeartSVG from "../../../../public/assets/images/black-heart.svg";
import FilledHeartSVG from "../../../../public/assets/images/heart-primary-filled.svg";

const { ARTISN, FEATURE_FLAGS } = CONSTANTS;
const { WITH_SHARE_PRODUCT, WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_WISHLIST, WITH_PRODUCT_MODAL, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { WITH_PURCHASE, WITH_LOYALTY } = FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME } = ARTISN;
const { logViewProductDetails } = events.product;

const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const ProductMain: React.FC<Props> = props => {
  const { productId, product, isSignedIn } = props;
  const { onCloseModal, className, showShoppingCartDrawer } = props;
  const { isModal = false } = props;
  const t = useI18n();
  const [showBtnDescription, setShowBtnDescription] = useState(false);
  const [showCompleteDescription, setShowCompleteDescription] = useState(false);
  const [purchaseWithPoints, setPurchaseWithPoints] = useState(false);
  const descriptionText = useRef<HTMLParagraphElement>(null);
  const { isAnonymous } = useAuth();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const router = useRouter();
  const { minAmountForSale = 1 } = product ?? {};
  const [amount, setAmount] = useState(minAmountForSale ?? 1);
  const [comment, setComment] = useState("");
  const { analyticsInitialized } = useAnalytics();
  const viewProductDetailsLogged = useRef(false);
  const { images, categories } = product ?? {};
  const { description, available, maxAmountForSale } = product ?? {};
  const form = useProductForm(product);
  const { product: productForm } = form ?? {};
  const [category] = categories ?? [];
  const { categoryId, name: categoryName } = category ?? {};
  const selectedProductId = product?.productId;
  const ref = useRef(selectedProductId);
  const productRef = useRef(product);
  const [disable, setDisable] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const { selectedStore } = useStores();
  const shippingCost = useShippingCost();
  const { shoppingCart, temporalBenefit, setTemporalBenefit } =
    useShoppingCart();

  const modifiersAmount = useMemo(() => {
    const { questionsAndAnswers } = productForm ?? {};
    let amountTotal = 0;
    questionsAndAnswers?.forEach((question: any) => {
      const { answers } = question;
      answers?.forEach((answer: any) => {
        const { amount } = answer ?? {};
        if (amount) {
          amountTotal += amount;
        }
      });
    });
    return amountTotal;
  }, [productForm]);

  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);

  const selectedProductAmount = useMemo(() => {
    return products?.find(product => product.productId === selectedProductId)
      ?.amount;
  }, [products, selectedProductId]);

  //@ts-ignore
  const productCombo = product?.type === "COMBO";
  const icon = inCart ? <FilledHeartSVG /> : <HeartSVG />;
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const benefitProductId = getBenefitProductId(
    temporalBenefit,
    selectedBenefit
  );

  const initialValue = productCombo
    ? 0
    : selectedProductAmount ?? minAmountForSale ?? 1;
  const isBenefit = benefitProductId === selectedProductId;
  const { talkShopProvider } = useTalkShop();

  const handleDescription = () => {
    setShowCompleteDescription(prev => !prev);
  };

  const goToCategories = () => {
    router.push(`/categories/${categoryId}/${categoryName}`);
  };

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  const applyBenefitHandler = async (temporalBenefit: Benefit) => {
    try {
      await applyBenefit({
        benefitId: temporalBenefit.benefitId,
        product,
        shippingCost,
        anonymous: isAnonymous,
        productConfig: {
          amount: 1,
          store: selectedStore
        }
      });
      setTemporalBenefit(undefined);
    } catch (e) {
      createErrorNotification(e.message, "Cupones");
    }
  };

  const addToCartFinishHandler = async () => {
    if (talkShopProvider && WITH_TALK_SHOP) {
      router.push("/categories");
      return;
    }

    if (WITH_CART_DRAWER && !!selectedProduct) {
      if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();
      router.push("/categories");
    }

    if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();

    if (WITH_CART_DRAWER && !selectedProduct) {
      dismissAddToCartNotification();
      if (temporalBenefit) await applyBenefitHandler(temporalBenefit);
      createAddToCartNotification(product, showShoppingCartDrawer);
      router.replace("/categories");
      return;
    }

    if (WITH_CART_DRAWER) return;

    if (temporalBenefit) {
      await applyBenefitHandler(temporalBenefit);
      router.replace("/cart");
      return;
    }

    router.push("/categories");
  };

  const errorHandler = () => {
    if (!form) {
      return;
    }
    const errorGroup = form.renderer.find(group => group.status !== "OK");
    if (!errorGroup) {
      return;
    }
    scrollToElement(`modifier-group-${errorGroup.id}`, isModal);
  };

  const addProductHandler = async () => {
    if (!product || disable) return;
    setDisable(true);
    await addToWishlist();
    if (!inCart) setShowNotification(true);
    setDisable(false);
  };

  const shareButtonNode = WITH_SHARE_PRODUCT ? (
    <ShareButton
      className="ProductModal__share"
      config={{
        title: t.product.shareProduct,
        text: t.product.shareWithAnother,
        url: `${getFullPath()}`
      }}
    />
  ) : null;

  const productActionsNode = () => (
    <section className="ProductMain__actions">
      <Counter
        className="ProductMain__counter"
        min={minAmountForSale}
        max={isBenefit ? 1 : maxAmountForSale}
        value={productCombo ? modifiersAmount : undefined}
        maxDisabled={!!isBenefit}
        initialValue={initialValue}
        onChange={value => setAmount(value)}
        disabled={!available || productCombo}
      />
      <AddToCartButton
        className="ProductMain__addToCart"
        form={form}
        onError={errorHandler}
        config={{
          amount: productCombo ? 1 : amount,
          comment
        }}
        onFinish={addToCartFinishHandler}
        disabled={!available}
      />
    </section>
  );

  const heartNode = () => {
    if (!WITH_WISHLIST) return;
    return (
      <Clickable
        className="ProductMain__wishlist-heart"
        onClick={addProductHandler}
      >
        {icon}
      </Clickable>
    );
  };

  useEffect(() => {
    if (!product || viewProductDetailsLogged.current || !analyticsInitialized) {
      return;
    }
    logViewProductDetails({
      product
    });
    viewProductDetailsLogged.current = true;
  }, [analyticsInitialized, product]);

  useEffect(() => {
    if (productCombo) return;
    setAmount(minAmountForSale ?? 1);
  }, [minAmountForSale, productCombo]);

  useEffect(() => {
    if (!productRef.current || !productId) return;
    if (productId !== productRef.current?.productId) {
      setSelectedProduct(undefined);
    }
  }, [productId, setSelectedProduct]);

  useEffect(() => {
    ref.current = selectedProductId;
  }, [selectedProductId]);

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  useEffect(() => {
    const description = descriptionText.current;
    if (!description) return;
    if (description.offsetHeight < description.scrollHeight) {
      setShowBtnDescription(true);
    }
    return () => {};
  }, []);

  useOnUnmount(() => setSelectedProduct(undefined));

  return (
    <Styles
      className={`ProductMain ${className}`}
      isModal={isModal}
      showCompleteDescription={showCompleteDescription}
      purchaseWithPoints={purchaseWithPoints}
    >
      <div className="ProductMain__content">
        <section className="ProductMain__preview-image">
          <ProductDetailsImage images={images} modifiers={form} />
        </section>
        <section className="ProductMain__form" id="modifiers">
          <div className="ProductMain__baseInfo-icons">
            {product ? <ProductBaseInfo product={product} /> : null}
            {product && isModal ? (
              <div className="ProductMain__icons">
                {heartNode()}
                {shareButtonNode}
              </div>
            ) : null}
          </div>
          {!available ? (
            <ProductErrorMessage goToCategories={goToCategories} />
          ) : null}
          <section className="ProductMain__description">
            {/* <p className="ProductMain__description__title">{t.product.about}</p> */}
            <p className="ProductMain__description__text" ref={descriptionText}>
              {description}
            </p>
            {showBtnDescription ? (
              <Button
                type="LINK"
                className="ProductMain__description__btn"
                onClick={handleDescription}
              >
                {showCompleteDescription ? t.common.seeLess : t.common.seeMore}
              </Button>
            ) : null}
          </section>
          {WITH_PURCHASE && form && product?.questions ? (
            <>
              <ModifiersForm form={form} disabled={!available} />
              <CommentBox
                label="Escribe un comentario"
                placeholder="Escribe un comentario"
                name="comment"
                className="ProductMain__comment-box"
                onChange={e => setComment(e.target.value)}
                value={comment}
              />
            </>
          ) : null}
          {WITH_LOYALTY ? (
            <section className="ProductMain__points">
              <div>
                <p className="ProductMain__points--title">
                  {t.product.purchaseWithPoints}
                </p>
                <p className="ProductMain__points--points">
                  {/* TODO: Replace with endpoint data */}
                  340{t.product.availablePoints}
                </p>
              </div>
              <Switch
                checked={purchaseWithPoints}
                name="points"
                onChange={setPurchaseWithPoints}
              />
            </section>
          ) : null}
          {!isModal && WITH_PURCHASE ? productActionsNode() : null}
          <p className="ProductMain__limit">
            {t.product.limit}{" "}
            <Link href="/profile/support">
              <a className="ProductMain__limit-support">{t.product.support}</a>
            </Link>
          </p>
        </section>
        {isModal ? (
          <section className="ProductMain__wish-button">
            {WITH_WISHLIST && product ? (
              <>
                <AddToWishlistButton
                  className="ProductMain__wishlist-button"
                  product={product}
                  disabled={!isSignedIn}
                />
              </>
            ) : null}
            {WITH_SHARE_PRODUCT && product ? <>{shareButtonNode}</> : null}
          </section>
        ) : null}
        {isModal ? productActionsNode() : null}
      </div>
    </Styles>
  );
};

ProductMain.defaultProps = {
  className: ""
};

export default ProductMain;
