import { useVendors } from "@simple/contexts";
import { useCatalogues } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { useProducts } from "@simple/contexts";
import { useFetchProductDetails } from "@simple/services";
import React, { useEffect } from "react";

import Styles, { ModalHeaderStyled } from "./ProductModal.styles";
import { ModalIconStyled } from "./ProductModal.styles";
import { ProductModalProps as Props } from "./ProductModal.types";
import ProductMain from "components/product/ProductMain/ProductMain";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useWindowSize from "hooks/useWindowSize";
import { dismissAddToCartNotification } from "utils/notifications.utils";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { FEATURE_FLAGS, BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;
const { WITH_PRODUCT_MODAL } = FEATURE_FLAGS;

const ProductModal: React.FC<Props> = props => {
  const { className, isOpen, onClose, productId, onOpenDrawer } = props;
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const { selectedProduct } = useProducts();
  const { isAnonymous } = useAuth();
  const { width, height } = useWindowSize();

  const { storeId } = selectedStore ?? {};
  const productsDetailsResponse = useFetchProductDetails(productId, {
    catalogueId: selectedCatalogueId,
    storeId: storeId ?? 0,
    vendorId: selectedVendorId,
    withCategories: true
  });
  const { data: productData } = productsDetailsResponse;
  const { status } = productsDetailsResponse;
  const product = selectedProduct ?? productData;
  const { name = "" } = product ?? {};

  const showShoppingCartDrawer = () => {
    if (onOpenDrawer) onOpenDrawer();
    dismissAddToCartNotification();
  };

  const modalHeaderNode = (
    <ModalHeaderStyled id="modal-header">
      <p className="ProductModal__header">{name}</p>
    </ModalHeaderStyled>
  );

  const modalIconNode = (
    <ModalIconStyled>
      <CloseSVG />
    </ModalIconStyled>
  );

  const renderProductMain = () => {
    if (status === "idle" || status === "loading") return;
    if (status === "error") return;
    if (status === "success") {
      return (
        <ProductMain
          className="ProductModal__main"
          productId={productId}
          isSignedIn={!isAnonymous}
          // TODO: Backend product have minAmountForSale but artisn doest not
          product={product as any}
          isModal={WITH_PRODUCT_MODAL}
          showShoppingCartDrawer={showShoppingCartDrawer}
          onCloseModal={onClose}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    const responsiveViewCallback = () => {
      if (width > mobile) return;
      const productModal: HTMLDivElement | null =
        document?.querySelector(".ProductModal");
      const modalActions: HTMLDivElement | null = document?.querySelector(
        ".ProductMain__actions"
      );
      if (!productModal || !modalActions) return;
      const modalHeight = productModal.clientHeight;
      const bottom = modalHeight - height;
      productModal.style.paddingBottom = `${bottom}px`;
      modalActions.style.bottom = `${bottom}px`;
    };

    const timeout = setTimeout(responsiveViewCallback, 1000);

    return () => clearTimeout(timeout);
  }, [productId, height, width]);

  return (
    <Styles
      className={`ProductModal ${className}`}
      header={modalHeaderNode}
      closeIcon={modalIconNode}
      opened={isOpen}
      onClose={onClose}
    >
      {renderProductMain()}
    </Styles>
  );
};

ProductModal.defaultProps = {
  className: ""
};

export default ProductModal;
