import { useProducts } from "@simple/contexts";
import { Button, Image } from "artisn-ui-react";
import React, { MouseEvent } from "react";

import ModifierPrices from "../ModifierPrices/ModifierPrices";
import { PRODUCT_IMAGE_CONFIG } from "../ModifiersForm/ModifiersForm.helpers";
import Styles from "./RadioButtonModifier.styles";
import { RadioButtonModifierProps as Props } from "./RadioButtonModifier.types";
import useOnMount from "hooks/useOnMount";
import { getMostChosenOptionKey } from "utils/product.utils";

const RadioButtonModifier: React.FC<Props> = props => {
  const { modifier, disabled = false, preferences } = props;
  const { amount, name, totals, id, images } = modifier;
  const { handleChange, groupId } = modifier;
  const { selectedProduct } = useProducts();

  const iconActiveClassName = amount ? "RadioButtonModifier__icon--active" : "";
  const textActiveClassName = amount ? "RadioButtonModifier__text--active" : "";

  const onClickHandle = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    if (amount) {
      handleChange(0);
      return;
    }
    handleChange(1);
  };

  useOnMount(() => {
    const mostChosenOptionKey = getMostChosenOptionKey(preferences);
    if (selectedProduct || !mostChosenOptionKey) return;
    const key = `${groupId}-${id}`;
    if (key === mostChosenOptionKey) {
      handleChange(preferences[mostChosenOptionKey].amount);
    }
  });

  return (
    <Styles className="RadioButtonModifier" disabled={disabled}>
      <Button
        className="RadioButtonModifier__button"
        id={id}
        onClick={onClickHandle}
        value={amount}
      >
        <div className={`RadioButtonModifier__icon ${iconActiveClassName}`} />
        {images?.[0] ? (
          <Image
            alt={name}
            className="RadioButtonModifier__image"
            image={images[0]}
            config={PRODUCT_IMAGE_CONFIG}
            errorImage="https://res.cloudinary.com/dovstvjim/image/upload/v1627933617/Propeller/product_false_kxyniy.jpg"
            placeholder="blur"
          />
        ) : null}
        <p className={`RadioButtonModifier__text ${textActiveClassName}`}>
          {name}
        </p>
      </Button>
      <ModifierPrices totals={totals} />
    </Styles>
  );
};

RadioButtonModifier.defaultProps = {};

export default RadioButtonModifier;
