import { useStores, useVendors } from "@simple/contexts";
import { useCatalogues, useCountries } from "@simple/contexts";
import { useFetchProducts } from "@simple/services";
import { SearchBar, useSearchBar } from "artisn-ui-react";
import { Image, formatByCurrency } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { Product } from "artisn/types";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { imageConfig } from "./SearchBarProducts.helpers";
import Styles from "./SearchBarProducts.styles";
import { SearchBarContentStyled as ContentStyles } from "./SearchBarProducts.styles";
import { SearchBarProductsProps as Props } from "./SearchBarProducts.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";
import { dropdownConfigs } from "utils/common.utils";
import { getSanitizedProductTotals } from "utils/product.utils";
import { goToProductDetail } from "utils/seo.utils";

import ClockSVG from "../../../../public/assets/images/clock.svg";

const { Item } = SearchBar;
const { logSearchProductAttempt, logSearchProductSuccess } = events.product;
const { logSearchProductNotFound } = events.product;
const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;
const { tablet } = CONSTANTS.BREAKPOINTS;

const SearchBarProducts: React.FC<Props> = props => {
  const { className } = props;

  const [showSearchBarContent, setShowSearchBarContent] = useState(false);
  const [queryString, setQueryString] = useState("");
  const t = useI18n();
  const router = useRouter();
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedCountry } = useCountries();
  const { decimals } = selectedCountry;
  const { width: windowWidth } = useWindowSize();
  const isResponsive = windowWidth <= tablet;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};

  const productsResponse = useFetchProducts(
    {
      vendorId: selectedVendorId,
      catalogueId: selectedCatalogueId,
      storeId: storeId!,
      size: 10,
      query: queryString
    },
    !!queryString
  );
  const { data, isLoading } = productsResponse;
  const productsArray = useMemo(() => {
    return data?.pages.flatMap(page => page.data);
  }, [data]);

  const instanceSearchBar = useSearchBar({
    name: "principal-searchBar"
  });
  const { cleanSearchHistory, recentSearches } = instanceSearchBar;

  const queryChangeHandler = (value: string) => {
    setQueryString(value);

    logSearchProductAttempt({
      query: value,
      content_type: "food"
    });
  };

  const submitOnChangeHandler = useCallback(
    (value: string) => {
      if (value === queryString) {
        return false;
      }
      if (value.length >= 3) {
        return true;
      }
      const queryLength = queryString?.length ?? 0;
      if (value.length - queryLength > 1) {
        return true;
      }
      return false;
    },
    [queryString]
  );

  const onClickItem = (product: Product) => {
    setQueryString("");
    goToProductDetail(product);
  };

  const onClick = () => {
    setShowSearchBarContent(true);
  };

  const onClickCancel = () => {
    setShowSearchBarContent(false);
  };

  const onSubmit = (query: string) => {
    logSearchProductAttempt({ query });

    router.push({
      pathname: "/products",
      query: { q: query }
    });
  };

  const backdropConfig = {
    onClick: onClickCancel
  };

  const getFormattedPrice = (product: Product) => {
    const { totals } = getSanitizedProductTotals(product, { decimals });
    const { unitNetPrice } = totals ?? {};
    const normal = formatByCurrency(Number(unitNetPrice ?? 0), {
      ...selectedCountry,
      locale: "en-US"
    });
    return normal;
  };

  const SearchBarContentNode = (
    <ContentStyles className="SearchBarContent" queryString={!!queryString}>
      {recentSearches.length ? (
        <div className="SearchBarContent__recent-searches-container">
          <div className="SearchBarContent__section-info">
            <h3 className="SearchBarContent__section-title">
              {t.searchBar.recentSearch}
            </h3>
            <span
              onClick={cleanSearchHistory}
              className="SearchBarContent__clean-button"
            >
              {t.searchBar.cleanHistory}
            </span>
          </div>
          <div className="SearchBarContent__recent-searches-item-container">
            {recentSearches.map((item, index) => (
              <Item
                key={index}
                className="SearchBarContent__recent-searches-item"
                query={item}
                onClick={isResponsive ? () => onSubmit(item) : () => {}}
              >
                <p className="SearchBarContent__recent-searches-query">
                  {item}
                </p>
                <ClockSVG />
              </Item>
            ))}
          </div>
        </div>
      ) : null}
      <div className="SearchBarContent__content-container">
        <div className="SearchBarContent__section-info">
          <h3 className="SearchBarContent__section-title">
            {`Resultados de "${queryString}"`}
          </h3>
        </div>
        <div className="SearchBarContent__dropdown-items">
          {productsArray?.map((product, index) => {
            const { name, images } = product;

            return (
              <Item
                key={index}
                className="SearchBarContent__dropdown-item"
                onClick={() => onClickItem(product)}
                query={name.trim()}
              >
                <Image
                  alt={name}
                  image={images[0]}
                  config={imageConfig}
                  placeholder="blur"
                />
                <div className="SearchBarContent__item-info">
                  <p className="SearchBarContent__item-name">{name}</p>
                  <p className="SearchBarContent__item-prices">
                    {getFormattedPrice(product)}{" "}
                    {WITH_LOYALTY ? (
                      <span className="SearchBarContent__item-points-price">
                        pts
                      </span>
                    ) : null}
                  </p>
                </div>
              </Item>
            );
          })}
        </div>
      </div>
    </ContentStyles>
  );

  useEffect(() => {
    if (queryString && submitOnChangeHandler(queryString)) {
      if (productsArray?.length) {
        logSearchProductSuccess({
          query: queryString,
          content_type: "food"
        });
      }

      if (!isLoading && productsArray?.length === 0) {
        logSearchProductNotFound({
          query: queryString,
          content_type: "food"
        });
      }
    }
  }, [
    data,
    isLoading,
    productsArray?.length,
    queryString,
    submitOnChangeHandler
  ]);

  return (
    <Styles
      className={`SearchBarProducts ${className}`}
      queryString={!!queryString}
    >
      <SearchBar
        className="SearchBarProducts__search-bar field"
        placeholder="Buscar"
        onSubmit={onSubmit}
        onChange={queryChangeHandler}
        instance={instanceSearchBar}
        onInputClick={onClick}
        onInputBlur={onClickCancel}
        onClear={() => {
          setQueryString("");
          setShowSearchBarContent(false);
        }}
        dropdownConfigs={{
          ...dropdownConfigs,
          showDropdown: showSearchBarContent,
          position: "center"
        }}
        backdropConfig={backdropConfig}
      >
        {queryString ? SearchBarContentNode : null}
      </SearchBar>
    </Styles>
  );
};

SearchBarProducts.defaultProps = {
  className: ""
};

export default SearchBarProducts;
