import { Pocket } from "artisn-ui-react";
import React from "react";

import CheckboxModifier from "../CheckboxModifier/CheckboxModifier";
import CounterModifier from "../CounterModifier/CounterModifier";
import RadioButtonModifier from "../RadioButtonModifier/RadioButtonModifier";
import { getGroupAmount } from "./ModifiersGroup.helpers";
import Styles from "./ModifiersGroup.styles";
import { ModifiersGroupProps as Props } from "./ModifiersGroup.types";
import Divider from "components/global/Divider/Divider";
import useI18n from "hooks/useI18n";

const ModifiersGroup: React.FC<Props> = props => {
  const { modifierGroup, disabled, preferences } = props;
  const t = useI18n();
  const { name, modifiers, data, errors } = modifierGroup;
  const { id: groupId } = modifierGroup;
  const { required } = modifierGroup;
  const { min, max } = data;
  const disabledGroup = !max ? false : getGroupAmount(modifierGroup) >= max;

  const renderTitle = () => {
    return (
      <div className="ModifiersGroup__pocket-title">
        <div className="ModifiersGroup__title">
          <p className="ModifiersGroup__name">{name}</p>
          {/* <p className="ModifiersGroup__description">
            {t.modifiers.description}
          </p> */}
          {errors?.map((error, index) => (
            <p className="ModifiersGroup__error" key={index}>
              {error.code === 202
                ? `${t.modifiers.errorMin} ${min} ${t.modifiers.options}`
                : `${t.modifiers.errorMax} ${max} ${t.modifiers.options}`}
            </p>
          ))}
        </div>
        <div className="ModifiersGroup__required">
          {required ? t.modifiers.required : t.modifiers.optional}
        </div>
      </div>
    );
  };

  const renderModifiers = () => {
    return modifiers.map(modifier => {
      const { type, id } = modifier;

      switch (type) {
        case "RADIO":
          return (
            <RadioButtonModifier
              modifier={modifier}
              preferences={preferences}
              key={id}
              disabled={disabled}
            />
          );
        case "CHECKBOX":
          return (
            <CheckboxModifier
              modifier={modifier}
              key={id}
              disabled={disabled}
              maxDisabled={disabledGroup}
            />
          );
        case "COUNTER":
          return (
            <CounterModifier
              modifier={modifier}
              disabled={disabled}
              key={id}
              maxDisabled={disabledGroup}
            />
          );
        default:
          return (
            <CounterModifier
              disabled={disabled}
              modifier={modifier}
              key={id}
              maxDisabled={disabledGroup}
            />
          );
      }
    });
  };

  return (
    <Styles className="ModifiersGroup" id={`modifier-group-${groupId}`}>
      <Pocket
        className="ModifiersGroup__pocket"
        title={renderTitle()}
        defaultOpened={required}
      >
        {renderModifiers()}
        <Divider className="ModifiersGroup__divider" showMobile={false} />
      </Pocket>
    </Styles>
  );
};

ModifiersGroup.defaultProps = {};

export default ModifiersGroup;
