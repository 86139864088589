import styled from "styled-components";

import { ProductBaseInfoStyledProps as Props } from "./ProductBaseInfo.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { primary } = variables.fonts;

const ProductBaseInfoStyled = styled.div<Props>`
  font-family: ${primary};

  .ProductBaseInfo {
    &__brand {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--palette-gray-s0-l35);
      padding: 1.6rem 0;
    }

    &__name {
      font-size: 2rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      line-height: 2.8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-bottom: 0.4rem;

      @media (max-width: ${tablet}px) {
        white-space: normal;
      }
    }

    &__presentation {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: var(--palette-black-s0-l10);
      padding-bottom: 1.6rem;
    }
  }
`;

export default ProductBaseInfoStyled;
