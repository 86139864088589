import Link from "next/link";
import React from "react";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";

import FacebookSVG from "../../../../public/assets/images/facebook.svg";
import InstagramSVG from "../../../../public/assets/images/instagram.svg";
import LogoSVG from "../../../../public/assets/images/logo-footer.svg";
// Uncomment when Pure Nature have Instagram
// import TwitterSVG from "../../../../public/assets/images/twitter.svg";

const { FOOTER_URL } = CONSTANTS.EXTERNAL_URLS;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;

const Footer: React.FC<Props> = props => {
  const { className } = props;
  const { talkShopProvider } = useTalkShop();
  const t = useI18n();

  if (talkShopProvider && WITH_TALK_SHOP) return null;

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__content">
        <div className="Footer__row Footer__social">
          <LogoSVG />
          <ul className="Footer__social__wrapper">
            {/* TODO: UNCOMMENT when Pure Nature have twitter */}
            {/* <li className="Footer__social__icon">
              <Link href="https://twitter.com/" passHref>
                <a
                  className="Footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterSVG />
                </a>
              </Link>
            </li> */}
            <li className="Footer__social__icon">
              <Link href="https://www.facebook.com/PureNaturePets1" passHref>
                <a
                  className="Footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookSVG />
                </a>
              </Link>
            </li>
            <li className="Footer__social__icon">
              <Link href="https://www.instagram.com/purenaturepets/" passHref>
                <a
                  className="Footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramSVG />
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="Footer__row Footer__caption">
          <div className="Footer__right">
            <div className="Footer__privacy-and-terms">
              <Link href={FOOTER_URL} passHref>
                <a
                  className="Footer__privacy-and-terms__item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t.footer.navigation.legalDocs}
                </a>
              </Link>
              {/* <Link href="/terms" passHref>
                <a
                  className="Footer__privacy-and-terms__item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t.footer.navigation.terms}
                </a>
              </Link> */}
              {/* TODO: enable link when available  */}
              <Link href="/faq" passHref>
                <a className="Footer__privacy-and-terms__item">
                  {t.footer.faq}
                </a>
              </Link>
            </div>
            <div className="Footer__row Footer__copyright">
              {t.footer.copyright} <span>{t.footer.reserved}</span>
            </div>
          </div>
          <p className="Footer__row Footer__left">
            {t.footer.developed}
            <a
              className="Footer__privacy-and-terms__item Footer__developed"
              target="_blank"
              rel="noopener noreferrer"
              href="https://trade.ec"
            >
              {t.footer.trade}
            </a>
          </p>
        </div>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
