import styled from "styled-components";

import { SelectAddressButtonStyledProps as Props } from "./SelectAddressButton.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { primary } = variables.fonts;
const { tablet } = CONSTANTS.BREAKPOINTS;

const SelectAddressButtonStyled = styled.div<Props>`
  font-family: ${primary};

  &&& .Button {
    max-width: 20rem;
    height: 4.8rem;
    background-color: var(--palette-gray-s0-l98);
    border-radius: 2.4rem;
    padding: 0.4rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${tablet}px) {
      max-width: unset;
      width: 100%;
      background-color: var(--palette-orange-s92-l95);
      border: unset;
      border-radius: unset;
      justify-content: center;
      height: 2.8rem;
      margin-top: 0.8rem;
    }
  }

  .SelectAddressButton {
    &__text {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-align: left;
      color: var(--palette-black-s0-l10);
      max-width: 12rem;

      @media (max-width: ${tablet}px) {
        display: flex;
        flex-direction: row;
        max-width: 32rem;
        font-size: 1.4rem;
        align-items: center;
        font-weight: 600;
      }
    }

    &__span {
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: var(--palette-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: ${tablet}px) {
        width: 12rem;
        font-size: 1.4rem;
        padding-left: 0.8rem;
      }
    }

    &__icon {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default SelectAddressButtonStyled;
