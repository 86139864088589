import { useShoppingCart } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { useProducts } from "@simple/contexts";
import { useCountries } from "@simple/contexts";
import { usePostTalkShop } from "@simple/services";
import { events } from "artisn/analytics";
import { getShoppingCartProducts, removeProduct } from "artisn/shopping-cart";
import { setProduct, removeBenefit } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo } from "react";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { getCartMessage } from "../CartPayButton/CartPayButton.helpers";
import ShoppingCartProduct from "../ShoppingCartProduct/ShoppingCartProduct";
import Styles from "./CartProducts.styles";
import { CartProductsProps as Props } from "./CartProducts.types";
import ProductModal from "components/global/ProductModal/ProductModal";
import { auth } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import { sortByProductDate } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { goToProductDetail } from "utils/seo.utils";

const { logRemoveProductFromCart } = events.shoppingCart;
const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { WITH_PRODUCT_MODAL, WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { PHONE_NUMBER } = CONSTANTS.INTEGRATIONS.WHATSAPP;

const CartProducts: React.FC<Props> = props => {
  const { disabled, className } = props;
  const { isAnonymous, uid } = useAuth();
  const { shoppingCart, showModifiers, setTemporalBenefit } = useShoppingCart();
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const router = useRouter();
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const empty = (products && products.length === 0) || !shoppingCart;
  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};
  const { selectedStore } = useStores();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productId = selectedProduct ? selectedProduct.productId : "";
  const shippingCost = useShippingCost();
  const { talkShopIdentifier, talkShopProvider } = useTalkShop();
  const { mutate } = usePostTalkShop(uid);
  const { selectedCountry } = useCountries();
  const notInitialRender = useRef(false);
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;
  const queryClient = useQueryClient();

  const sortedProducts = useMemo(
    () => products?.sort(sortByProductDate),
    [products]
  );

  const deleteProductHandler = async (product: CartProduct) => {
    try {
      if (!shoppingCartId || !selectedStore) return;
      const editedShoppingCart = await removeProduct(product, {
        anonymous: isAnonymous,
        shoppingCartName
      });
      if (editedShoppingCart) {
        const productsCount =
          getShoppingCartProducts(editedShoppingCart).length;
        if (!productsCount) {
          await auth().currentUser?.getIdToken(true);
          queryClient.refetchQueries([uid, "loyalty"]);
        }
      }

      // If there is a benefit applied to the cart
      if (selectedBenefit) {
        const { benefitId, type } = selectedBenefit;
        /* If selected benefit is of type PRODUCT, reset context because remove
        product function already deletes benefits from cart */
        if (product.benefitId === benefitId) setTemporalBenefit(undefined);

        /* If selected benefit is of type ALTER_DELIVERY and there is just one
          product on the cart, remove the benefit and reset context */
        if (type === "ALTER_DELIVERY" && products?.length === 1) {
          const { benefitId } = selectedBenefit;

          await removeBenefit({
            benefitId,
            shippingCost,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous
          });
          setTemporalBenefit(undefined);
        }
      }

      logRemoveProductFromCart({
        cartId: shoppingCartId,
        product,
        store: selectedStore
      });
    } catch (e) {
      createErrorNotification(e.message);
    }
  };

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const isPageProductDetail = () => {
    const pathname = router.pathname.split("/").slice(1);
    const isCorrectPage =
      pathname.length === 3 && pathname[2] === "[productName]";

    return isCorrectPage;
  };

  const onClickProduct = (product: CartProduct) => {
    setSelectedProduct(product);
    if (WITH_PRODUCT_MODAL && !isPageProductDetail()) {
      onClickModal();
    } else {
      goToProductDetail(product);
    }
  };

  const onChangeQuantity = (amount: number, product: CartProduct) => {
    setProduct(product, {
      amount,
      shoppingCartName,
      anonymous: isAnonymous
    });
    notInitialRender.current = true;
  };

  const sendMessage = useCallback(() => {
    const talkShopData = {
      to: `whatsapp:+${talkShopIdentifier.trim()}`,
      from: `whatsapp:+${PHONE_NUMBER}`,
      body: `${getCartMessage(
        products,
        showModifiers,
        shoppingCart,
        selectedCountry
      )}`
    };

    if (talkShop) {
      mutate(talkShopData);
      notInitialRender.current = false;
    }
  }, [
    products,
    selectedCountry,
    shoppingCart,
    showModifiers,
    talkShopIdentifier,
    mutate,
    talkShop
  ]);

  useEffect(() => {
    if (!shoppingCart || !notInitialRender.current || empty) return;
    sendMessage();
  }, [shoppingCart, sendMessage, empty]);

  return (
    <Styles className={`CartProducts ${className}`}>
      {sortedProducts?.map((product, index) => {
        const { amount, productId, benefitId } = product;
        const isBenefitProduct =
          selectedBenefit && benefitId === selectedBenefit.benefitId;
        const modifiers = showModifiers(product);
        const { maxAmountForSale } = product ?? {};
        //@ts-ignore
        const { minAmountForSale } = product ?? {};
        const { questionsAndAnswers } = product ?? {};
        let amountTotal = 0;
        questionsAndAnswers?.forEach((question: any) => {
          const { answers } = question;
          answers?.forEach((answer: any) => {
            const { amount } = answer ?? {};
            if (amount) {
              amountTotal += amount;
            }
          });
        });

        return (
          <ShoppingCartProduct
            product={product}
            initialQuantity={amount}
            onChangeQuantity={(amount: number) =>
              onChangeQuantity(amount, product)
            }
            onDelete={() => deleteProductHandler(product)}
            onClick={() => onClickProduct(product)}
            key={`${productId}-${index}`}
            disabled={disabled || isBenefitProduct}
            modifiers={modifiers}
            minCounter={minAmountForSale}
            maxCounter={maxAmountForSale}
            amountTotal={amountTotal}
          />
        );
      })}
      <ProductModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        productId={productId}
      />
    </Styles>
  );
};

CartProducts.defaultProps = {
  className: ""
};

export default CartProducts;
