import { getPricesByCategory } from "@simple/utils";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import Styles from "./ModifierPrices.styles";
import { ModifierPricesProps as Props } from "./ModifierPrices.types";
import useI18n from "hooks/useI18n";

const ModifierPrices: React.FC<Props> = props => {
  const { totals, className, netPriceStyle, unitPriceStyle, prices } = props;
  const { unitNetPrice, netPrice } = totals;
  const price = getPricesByCategory(prices);
  const t = useI18n();

  return (
    <Styles className={`ModifierPrices ${className}`}>
      {price && price > 0 ? (
        <>
          <p className={`ModifierPrices__sum ${netPriceStyle}`}>
            +{" "}
            {formatByCurrency(netPrice, {
              currency: "USD"
            })}
          </p>
          <p className={`ModifierPrices__unitPrice ${unitPriceStyle}`}>
            {formatByCurrency(unitNetPrice, {
              currency: "USD"
            })}{" "}
            {t.modifiers.perUnit}
          </p>
        </>
      ) : null}
    </Styles>
  );
};

ModifierPrices.defaultProps = {};

export default ModifierPrices;
