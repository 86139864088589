import styled from "styled-components";

import { ProductDetailsImageStyledProps as Props } from "./ProductDetailsImage.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, tablet } = BREAKPOINTS;

const ProductDetailsImageStyled = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .ProductDetailsImage {
    &__thumbnail-carousel {
      padding-right: 1.6rem;

      @media (max-width: ${mobile}px) {
        padding-right: 0;
        padding-top: 1.6rem;
      }

      &--desktop {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile + 1}px) {
          display: none;
        }
      }
    }

    &__image {
      &--tablet {
        @media (min-width: ${tablet + 1}px) {
          display: none;
        }

        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile + 1}px) {
          display: none;
        }
      }
    }

    &__zoom-image {
      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }

        .ZoomImage__lens {
          border-radius: 1.6rem;
          border-color: var(--palette-gray-s0-l70);
        }
      }
    }

    &__light-box {
      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }
  }
`;

export default ProductDetailsImageStyled;
