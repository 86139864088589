import styled from "styled-components";

import { CounterModifierStyledProps as Props } from "./CounterModifier.types";
import variables from "styles/util/variables";

const { primary } = variables.fonts;

const CounterModifierStyled = styled.div<Props>`
  font-family: ${primary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .CounterModifier {
    &__info {
      display: flex;
      align-items: center;
    }

    &__counter {
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-purplet-s48-l39);
      border-radius: 10rem;
      overflow: hidden;
      display: flex;

      & .Counter {
        &__button {
          width: 4.2rem;
          height: 3.2rem;
          background-color: var(--palette-white);

          svg {
            path {
              fill: var(--palette-purplet-s48-l39);
            }
          }
        }

        &__quantity {
          background-color: var(--palette-white);
          color: var(--palette-black-s0-l10);
        }
      }
    }

    &__image {
      margin: 0 0.8rem;
    }

    &__title {
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--palette-black-s0-l10);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding-bottom: 2rem;
      font-weight: 400;
    }

    &__prices {
      align-items: flex-start;
    }

    &__netPrice {
      color: var(--palette-gray-s0-l35);
      padding-bottom: 0.4rem;
    }

    &__unitPrice {
      color: var(--palette-gray-s0-l70);
    }
  }
`;

export default CounterModifierStyled;
