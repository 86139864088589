import styled from "styled-components";

import { CategoriesButtonStyledProps as Props } from "./CategoriesButton.types";
import variables from "styles/util/variables";

const { primary } = variables.fonts;

const CategoriesButtonStyled = styled.div<Props>`
  font-family: ${primary};

  .CategoriesButton {
    &__container {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 15.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__title {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 600;
      color: var(--palette-white);
      padding-right: 0.4rem;
    }

    &__chevron {
      cursor: pointer;

      path {
        fill: var(--palette-white);
      }
    }
  }
`;

export default CategoriesButtonStyled;
