import styled from "styled-components";

import { CartButtonStyledProps as Props } from "./CartButton.types";
import CONSTANTS from "config/constants";

const { WITH_WISHLIST } = CONSTANTS.FEATURE_FLAGS;

const CartButtonStyled = styled.div<Props>`
  cursor: pointer;

  .CartButton {
    &--heart {
      display: none;

      path {
        fill: var(--palette-primary);
      }

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST && props.isSignedIn
            ? "block"
            : "none"};
      }
    }

    &--cart {
      display: block;

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST ? "none" : "block"};
      }
    }
  }

  &&& .Badge__value {
    background-color: var(--palette-yellow-s100-l50);
    color: var(--paletter-black);
  }
`;

export default CartButtonStyled;
