import { Clickable } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./GoBack.styles";
import { GoBackProps as Props } from "./GoBack.types";
import useI18n from "hooks/useI18n";

import ChevronLeft from "../../../../public/assets/images/chevron-left-black.svg";

const GoBack: React.FC<Props> = props => {
  const t = useI18n();
  const { title = t.common.back, width = 0, className, to } = props;
  const { iconClassName } = props;
  const router = useRouter();

  const clickHandler = () => {
    if (to) {
      router.replace(to);
      return;
    }
    router.back();
  };

  return (
    <Styles className={`GoBack ${className}`} width={width} title={title}>
      <Clickable
        onClick={clickHandler}
        className={`GoBack__icon ${iconClassName}`}
      >
        <ChevronLeft />
        {title ? <p className="GoBack__title">{title}</p> : null}
      </Clickable>
    </Styles>
  );
};

GoBack.defaultProps = {
  className: ""
};

export default GoBack;
