import styled from "styled-components";

import { RedeemCouponInputStyledProps as Props } from "./RedeemCouponInput.types";

const RedeemCouponInputStyled = styled.div<Props>`
  position: relative;
  width: 100%;

  .RedeemCouponInput {
    &__text-input {
      .TextInput__label {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--palette-gray-s0-l35);
        margin-bottom: 0.6rem;
      }

      .TextInput__input {
        padding: 0.6rem;
        border: 0.1rem solid var(--palette-gray-s0-l95);
        background-color: var(--palette-gray-s0-l98);
        border-radius: 10rem;
        height: 5.6rem;
      }
    }

    &__input-button {
      position: absolute;
      top: 3.5rem;
      right: 0.8rem;
      height: 3.6rem;
      padding: 1.6rem 1rem;
      font-weight: 600;
      z-index: 1;
      font-size: 1.6rem;

      .Button__wrapper {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2rem;
        color: var(--palette-green-s90-l35);
      }
    }
  }
`;

export default RedeemCouponInputStyled;
