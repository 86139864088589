import styled from "styled-components";

import { NotificationsButtonStyledProps as Props } from "./NotificationsButton.types";

const NotificationsButtonStyled = styled.div<Props>`
  .NotificationsButton {
  }
`;

export default NotificationsButtonStyled;
