import styled from "styled-components";

import { GoBackStyledProps as Props } from "./GoBack.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { primary } = variables.fonts;

const GoBackStyled = styled.div<Props>`
  font-family: ${primary};
  width: ${props => (props.title ? "auto" : "2.4rem")};
  display: inline-flex;
  align-items: flex-end;
  padding-top: 7.2rem;
  padding-bottom: 2.4rem;

  @media (max-width: ${tablet}px) {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
    padding-left: 1.6rem;
  }

  .GoBack {
    &__icon {
      display: flex;
      align-items: center;
      padding: 0;
    }

    &__title {
      display: inline-block;
      width: ${props => (props.width ? props.width + "px" : "auto")};
      padding-left: 0.8rem;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }
`;

export default GoBackStyled;
