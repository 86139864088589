import { useFetchUser } from "@simple/services";
import React from "react";

import Styles from "./Welcome.styles";
import { WelcomeProps as Props } from "./Welcome.types";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const Welcome: React.FC<Props> = props => {
  const { className } = props;
  const authContext = useAuth();
  const { data: user } = useFetchUser(authContext);
  const t = useI18n();

  return (
    <Styles className={`Welcome ${className}`}>
      {t.common.hello} {user ? ` ${user.name}` : null}
    </Styles>
  );
};

Welcome.defaultProps = {};

export default Welcome;
