import { useCountries } from "@simple/contexts";
import { titleCase } from "@simple/utils";
import { Clickable } from "artisn-ui-react";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import "dayjs/locale/es";

import Styles from "./CouponDetail.styles";
import { CouponDetailProps as Props } from "./CouponDetail.types";
import useI18n from "hooks/useI18n";

import CloseSVG from "../../../../public/assets/images/close.svg";

const CouponDetail: React.FC<Props> = props => {
  const { benefit, selected = false, inCart, className, removeError } = props;
  const { showRemove = true, onRemove } = props;
  const { title, description, expirationDate } = benefit ?? {};
  const { selectedCountry } = useCountries();
  const t = useI18n();
  const { locale } = selectedCountry;
  const formattedExpirationDate = useMemo(() => {
    return dayjs(expirationDate).locale(locale).format("DD [de] MMMM, YYYY");
  }, [expirationDate, locale]);

  return (
    <>
      <Styles className={`CouponDetail ${className}`} selected={selected}>
        <div className="CouponDetail__container">
          <div className="CouponDetail__left">
            <div className="CouponDetail__left__label">
              <p className="CouponDetail__left__label__title">{title}</p>
              <p className="CouponDetail__left__label__description">
                {description}
              </p>
              <p className="CouponDetail__left__label__expiration-date">{`${
                t.coupons.dateValid
              }: ${titleCase(formattedExpirationDate)}`}</p>
            </div>
          </div>
          {selected ? (
            <div className="CouponDetail__right">
              <div className="CouponDetail__left__selected" />
            </div>
          ) : !inCart ? (
            <div className="CouponDetail__left__icon" />
          ) : null}
          {inCart && showRemove ? (
            <div className="CouponDetail__right">
              <Clickable
                className="CouponDetail__remove"
                onClick={() => onRemove?.()}
              >
                <CloseSVG />
              </Clickable>
            </div>
          ) : null}
        </div>
        {removeError ? (
          <p className="CouponDetail__remove-error">{removeError}</p>
        ) : null}
      </Styles>
    </>
  );
};

CouponDetail.defaultProps = {
  className: ""
};

export default CouponDetail;
