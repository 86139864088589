// Category Page helper functions and data
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, desktop, tablet } = BREAKPOINTS;

export const imageConfigurations = (width: number) => {
  if (width <= mobile) {
    return { width: 280, height: 280 };
  }

  if (width <= tablet) {
    return { width: 300, height: 300 };
  }

  if (width <= desktop) {
    return { width: 360, height: 360 };
  }

  return { width: 400, height: 400 };
};

export const imageLightboxConfigurations = (width: number) => {
  if (width > tablet && width < desktop) {
    return { width: 600, height: 600 };
  }

  if (width < tablet) {
    return { width: 400, height: 400 };
  }

  return { width: 800, height: 800 };
};
