import styled from "styled-components";

import { CheckboxModifierStyledProps as Props } from "./CheckboxModifier.types";

const CheckboxModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  &.CheckboxModifier .Checkbox {
    &__label {
      padding: 0;

      &--active {
        font-weight: 600;
      }
    }

    &__check {
      margin-right: 0.8rem;
    }
  }

  .CheckboxModifier {
    &__label {
      display: flex;
      align-items: center;
    }

    &__title {
      padding-left: 0.8rem;
      font-size: 1.2rem;
      color: var(--palette-black-s0-l10);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export default CheckboxModifierStyled;
