import styled from "styled-components";

import { UserTabsMenuItemStyledProps as Props } from "./UserTabsMenuItem.types";

const UserTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  }

  .UserTabsMenuItem {
  }
`;

export default UserTabsMenuItemStyled;
