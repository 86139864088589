import { useFetchUser } from "@simple/services";
import React, { useEffect, useState } from "react";

import ModifiersGroup from "../ModifiersGroup/ModifiersGroup";
import Styles from "./ModifiersForm.styles";
import { ModifiersFormProps as Props } from "./ModifiersForm.types";
import useAuth from "contexts/auth/auth.context.hooks";
import { ProductPreferences } from "types/common.types";
import { getProductPreferences } from "utils/product.utils";

const ModifiersForm: React.FC<Props> = props => {
  const { form, disabled } = props;
  const { renderer, product } = form;
  const auth = useAuth();
  const [preferences, setPreferences] = useState<ProductPreferences>();
  const { data: user } = useFetchUser(auth);
  const { uid: userUid } = user ?? {};

  useEffect(() => {
    (async () => {
      const productPreferences = await getProductPreferences(product, userUid);
      setPreferences(productPreferences);
    })();
  }, [product, userUid]);

  if (!preferences) return null;

  return (
    <Styles className="ModifiersForm">
      {renderer.map(group => {
        return (
          <ModifiersGroup
            preferences={preferences}
            key={group.id}
            modifierGroup={group}
            disabled={disabled}
          />
        );
      })}
    </Styles>
  );
};

ModifiersForm.defaultProps = {};

export default ModifiersForm;
