import { useCountries } from "@simple/contexts";
import { getProductTotals } from "artisn/products";
import { useRouter } from "next/router";
import React from "react";

import { defaultConfig } from "./AddToCartButton.helpers";
import { useAddToCart } from "./AddToCartButton.hooks";
import Styles from "./AddToCartButton.styles";
import { AddToCartButtonProps as Props } from "./AddToCartButton.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { WITH_ANONYMOUS } = CONSTANTS.FEATURE_FLAGS;

const AddToCartButton: React.FC<Props> = props => {
  const { className, disabled, form, config = defaultConfig } = props;
  const { shouldReplace, onClick, isAdding } = useAddToCart({ ...props });
  const { currencySymbol } = useCountries().selectedCountry;
  const { push } = useRouter();
  const { isAnonymous } = useAuth();

  const { product, status } = form ?? {};
  const totals = product ? getProductTotals(product, config.amount) : undefined;
  const { netPrice } = totals ?? {};
  const price = typeof netPrice !== "undefined" ? netPrice.toFixed(2) : "0.00";
  const noOKClass = status !== "OK" ? "AddToCartButton__btn__no-ok" : "";

  const clickHandler = async () => {
    if (!WITH_ANONYMOUS && isAnonymous) {
      push("/signin");
      return;
    }
    await onClick();
  };

  return (
    <Styles className={`AddToCartButton ${className}`}>
      <Button
        className={`${noOKClass}`}
        onClick={clickHandler}
        disabled={disabled || !product?.available}
        isLoading={isAdding}
        color="primary"
      >
        <p className="AddToCartButton__text">
          {shouldReplace ? (
            "Actualizar"
          ) : (
            <>
              <span className="AddToCartButton__text--desktop">
                Añadir al carrito
              </span>
              <span className="AddToCartButton__text--mobile">Añadir</span>
            </>
          )}
        </p>
        <p className="AddToCartButton__separator">|</p>
        <p className="AddToCartButton__text">{`${currencySymbol} ${price}`}</p>
      </Button>
    </Styles>
  );
};

AddToCartButton.defaultProps = {
  className: ""
};

export default AddToCartButton;
