import { ShoppingCartSummary } from "artisn-ui-react";
import styled from "styled-components";

import { ShoppingCartSummaryStyledProps as Props } from "./ShoppingCartSummary.types";
import variables from "styles/util/variables";

const { primary } = variables.fonts;

const ShoppingCartSummaryStyled = styled(ShoppingCartSummary)<Props>`
  font-family: ${primary};
  padding: 2.6rem;

  .ShoppingCartSummary__title {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    color: var(--palette-gray-s0-l35);
  }

  .ShoppingCartItem {
    & > * {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      color: var(--palette-gray-s0-l25);
    }
  }

  .ShoppingCartSummaryTotal {
    & > * {
      font-weight: 600;
    }

    &__value {
      direction: rtl;
    }
  }

  .ShoppingCartSummaryTaxes {
    &__value {
      direction: rtl;
    }
  }

  .ShoppingCartSummarySubtotal {
    &__value {
      direction: rtl;
    }
  }

  .ShoppingCartSummaryDiscount {
    &__value {
      direction: rtl;
    }
  }

  .ShoppingCartSummaryShippingCost {
    &__value {
      direction: rtl;
      color: var(--palette-gray-s0-l25);
    }
  }

  .ShoppingCartSummary {
  }
`;

export default ShoppingCartSummaryStyled;
