import { useShoppingCart } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { events } from "artisn/analytics";
import { removeProduct, setProduct } from "artisn/shopping-cart";
import { CartProduct, Product } from "artisn/types";
import React, { useState } from "react";

import ShoppingCartProduct from "../../Cart/ShoppingCartProduct/ShoppingCartProduct";
import Styles from "./UpsaleModal.styles";
import { UpsaleModalProps as Props } from "./UpsaleModal.types";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";

const { logRemoveProductFromCart } = events.shoppingCart;

const UpsaleModal: React.FC<Props> = props => {
  const { opened, products, onClose, onSuccess } = props;
  const { shoppingCart } = useShoppingCart();
  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};
  const [count, setCount] = useState(0);
  const { selectedStore } = useStores();
  const { isAnonymous } = useAuth();

  const deleteProductHandler = (product: CartProduct) => {
    removeProduct(product, { shoppingCartName, anonymous: isAnonymous });

    if (!shoppingCartId || !selectedStore) return;

    logRemoveProductFromCart({
      cartId: shoppingCartId,
      product,
      store: selectedStore
    });
  };

  const changeHandler = (amount: number, product: Product) => {
    if (!amount) {
      deleteProductHandler(product as CartProduct);
      return;
    }
    setProduct(product, { amount, shoppingCartName });
  };

  const headerNode = (
    <div className="UpsaleModal__header">
      <p className="UpsaleModal__title">Esto podria gustarte</p>
      <p className="UpsaleModal__description">
        Personas que compraron productos que aparecen en tu carrito también
        compraron
      </p>
    </div>
  );

  return (
    <Styles
      className="UpsaleModal"
      opened={opened}
      header={headerNode}
      onClose={onClose}
      closeOnClickOutside={false}
    >
      <div className="UpsaleModal__products">
        {products.map(product => {
          const { productId } = product;
          return (
            <ShoppingCartProduct
              className="UpsaleModal__product"
              key={productId}
              product={product}
              onChangeQuantity={amount => changeHandler(amount, product)}
              onSubtract={() => setCount(prev => prev - 1)}
              onAdd={() => setCount(prev => prev + 1)}
              minCounter={0}
            />
          );
        })}
      </div>
      <div className="UpsaleModal__actions">
        {count ? (
          <Button className="UpsaleModal__button" onClick={onSuccess}>
            Continuar
          </Button>
        ) : (
          <Button
            className="UpsaleModal__button"
            color="black"
            onClick={() => {
              onClose();
              onSuccess();
            }}
          >
            No gracias
          </Button>
        )}
      </div>
    </Styles>
  );
};

UpsaleModal.defaultProps = {};

export default UpsaleModal;
