import styled from "styled-components";

import { BenefitsListStyledProps as Props } from "./BenefitsList.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const BenefitsListStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0.8rem;
  font-family: ${primary};

  .BenefitsList {
    &__buttonGroup {
      flex-direction: column;
      align-items: start;
    }

    &__button {
      border: none;
      width: 100%;
      flex: 1;
      padding: 0.8rem 0;

      &.Button--active {
        border: none;

        .CouponDetail__left__icon {
          border: 0.7rem solid var(--palette-primary);
        }
      }

      @media (max-width: ${mobile}px) {
        padding: 0;
      }
    }
  }
`;

export default BenefitsListStyled;
